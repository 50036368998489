var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('Head',{attrs:{"headTitle":_vm.headTitle}}),_c('div',{staticClass:"b-container"},[_c('div',{staticClass:"con-1"},[_c('div',{staticClass:"mod1 flex flex-v"},[_c('div',{staticClass:"item item1"},[_vm._m(0),_c('div',{ref:"chart1",staticClass:"chart flex-11"})])]),_c('div',{staticClass:"mod1 flex flex-v cenrs"},[_c('div',{staticClass:"item item2"},[_vm._m(1),_c('div',{ref:"chart2",staticClass:"chart"})])]),_c('div',{staticClass:"mod1 flex flex-v"},[_c('div',{staticClass:"item item3"},[_vm._m(2),_c('div',{ref:"chart4",staticClass:"chart"})])])]),_c('div',{staticClass:"con-c flex flex-v relative"},[_c('div',{staticClass:"mod-cen"},[_c('div',{staticClass:"mod2-map"},[_c('div',{staticClass:"map_box"},[_c('div',{staticClass:"item_education item item1"},[_c('div',{staticClass:"txt"},[_c('p',[_c('span',{staticClass:"tit"},[_vm._v("教育机构")]),_c('em',[_vm._v(" "+_vm._s(_vm.webData.educationIns)+" ")]),_c('span',{staticClass:"tit tit1"},[_vm._v("所")])])])]),_c('div',{staticClass:"item_hosptial item item2"},[_c('div',{staticClass:"txt"},[_c('p',[_c('span',{staticClass:"tit"},[_vm._v("医疗机构")]),_c('em',[_vm._v(_vm._s(_vm.webData.medicalIns))]),_c('span',{staticClass:"tit"},[_vm._v("所")])])])]),_vm._m(3),_vm._m(4)])])])]),_c('div',{staticClass:"con-2"},[_c('div',{staticClass:"mod4 flex flex-v"},[_c('div',{staticClass:"item item1"},[_vm._m(5),_c('div',{ref:"chart5",staticClass:"chart flex-11"})])]),_c('div',{staticClass:"mod4 flex flex-v cenrs"},[_c('div',{staticClass:"item item2"},[_vm._m(6),_c('div',{ref:"chart3",staticClass:"chart"})])]),_c('div',{staticClass:"mod4 flex flex-v"},[_c('div',{staticClass:"item item3"},[_vm._m(7),_c('div',{ref:"chart6",staticClass:"chart"})])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("学校人数统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("升学率")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("优秀教师配比")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_education item item3"},[_c('div',{staticClass:"txt"},[_c('p',[_c('span',{staticClass:"tit"},[_vm._v("在校学生")]),_c('em',[_vm._v(" 1281369 ")]),_c('span',{staticClass:"tit tit1"},[_vm._v("名")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_education item item4"},[_c('div',{staticClass:"txt"},[_c('p',[_c('span',{staticClass:"tit"},[_vm._v("医院医生总计")]),_c('em',[_vm._v(" 1114 ")]),_c('span',{staticClass:"tit tit1"},[_vm._v("名")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("医院就诊人数统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("优质医疗资源引入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-pub-tt-jy flex"},[_c('span',{staticClass:"bt"},[_vm._v("优秀医师资质配比")])])
}]

export { render, staticRenderFns }